<template>
  <div class="cidian">
    <div class="flex between">
      <div class="title">消息详情</div>
      <el-button type="primary" style="float: right" @click="handleReturn">返回上一级</el-button>
    </div>
    <div class="flex between seach">
      <div class="activity-list">
        <div v-if="activityList.length > 0">
          <div style="margin-bottom: 20px;">系统管理员为您推送了以下活动信息：</div>
          <activityList :pageListTotal="pageListTotal" :pageSize="pageSize" :currentPage="currentPage" :activityList="activityList" @change="handleChange($event)"></activityList>
        </div>
        <div class="none-data" v-else>
          <img src="@/assets/pc/images/personal/none.png" alt="" />
          <span style="font-size: 14px; color: #b0b0b0">暂无内容</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import activityList from "./components/activityList";
import { request } from "@/network";
import { getMemberId } from "@/assets/public/utils/token";
export default {
  components: {
    activityList,
  },
  data() {
    return {

      pageListTotal: 0,
      pageSize: 9,
      currentPage: 1,
      activityList: [],
    };
  },
  watch: {
  },
  async mounted() {
    console.log(this.$route.query.id)
    this.getActivityList();
  },
  methods: {
    dataToDetail(clickId) {
      this.$router.push({ name: "activityDetails", query: { clickId } });
    },
    async getActivityList() {
      const data = {
        memberId: getMemberId(),
        noticeId: this.$route.query.id,
        noticeType: 6,
        pageNo: this.currentPage,
        pageSize: this.pageSize
      };

      const { data: res } = await request(
        {
          method: "GET",
          url: "/pcp/PersonalCenter/getNoticeInfo",
          params: data,
        },
        true
      );
      if (res.code !== 200) return this.$message.error("获取数据异常");
      this.activityList = res.data.list;
      this.pageListTotal = res.data.total;
    },
    async handleChange(value) {
      console.log(value)
      this.currentPage = value
      this.getActivityList();
    },
    handleReturn() {
      this.$router.back();
    },
  },
};
</script>

<style lang="less" scoped>
.cidian {
  background-color: #fff;
  padding: 43px 0px;
  .seach {
    margin: 30px 0;
    > div {
      > div {
        margin-bottom: 10px;
      }
    }
    > div:nth-of-type(3) {
      width: 300px;
    }
  }
}
.title {
  color: rgba(16, 16, 16, 100);
  font-size: 24px;
  text-align: left;
  font-family: PingFangSC-regular;
}
.activity-list {
  width: 100%;
}
.none-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 340px;
}
/deep/ .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background-color: #156ed0;
  border-color: #156ed0;
}
.el-button--primary {
  background-color: #156ed0;
  border-color: #156ed0;
}
</style>